import React, { FC, memo } from 'react'
import * as S from './styles'
import { CheckboxProps } from './types'
import { Icon } from '../Icon'
import { Text } from '../Text'

export const SelectForCalcMaterials: FC<CheckboxProps> = memo(
  ({
    kind = 'circle',
    type = 'checkbox',
    id,
    name,
    value,
    checked,
    onChange,
    label,
    disabled,
    description,
    openDropdown,
    setOpenDropdown,
    ...rest
  }) => {
    return (
      <S.CheckboxContainer
        htmlFor={id}
        checked={checked}
        className={openDropdown && checked ? 'checked-label' : ''}
        openDropdown={openDropdown && checked}
        disabled={disabled}
        {...rest}
      >
        <S.HiddenInput type={type} id={id} name={name} value={value} checked={checked} onClick={onChange} readOnly />
        <S.Shape kind={kind} checked={checked}>
          <S.InnerShape kind={kind} checked={checked} />
        </S.Shape>
        <S.Infos displayType='block'>
          <Text
            as='span'
            color='normal'
            weight={checked ? 'bold' : 'normal'}
            margin='0 0.25rem 0 0'
            dangerouslySetInnerHTML={{ __html: label }}
          />
          {description && (
            <Text as='span' color='weak' margin='0'>
              {description}
            </Text>
          )}
        </S.Infos>
        <S.Icon
          key={'icon-button'}
          role='button'
          tabIndex={0}
          onClick={() => setOpenDropdown((previous) => ({ ...previous, state: !(openDropdown && checked), id: id }))}
          onKeyPress={() => setOpenDropdown((previous) => ({ ...previous, state: !(openDropdown && checked), id: id }))}
        >
          <Icon name={openDropdown && checked ? 'minus-xl' : 'plux-xl'} size='s1' />
        </S.Icon>
      </S.CheckboxContainer>
    )
  }
)

SelectForCalcMaterials.displayName = 'SelectForCalcMaterials'
