import styled from 'styled-components'
import { Box, theme } from '@/client/components'
const { borders, breakpoint, color, font, space, zindex } = theme

export const Result = styled.div`
  display: block;
  height: 100%;
`

export const ResultWrapper = styled.aside`
  position: sticky;
  top: 0;
`

export const ResultBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${space.xl2} ${space.xs} 0;
  position: relative;
  @media (min-width: ${breakpoint.xl4}) {
    padding: ${space.xl2} ${space.xs} 0;
  }
`
export const BoxShadow = styled(Box)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${space.sm};
  z-index: ${zindex.sticky};
  background-color: ${color.grayscale.dark};
  color: ${color.grayscale.white};
  font-weight: ${font.weight.bold};
  font-size: ${font.size.h1};
  @media (min-width: ${breakpoint.hd}) {
    padding: ${space.xl3} ${space.xl} 0;
  }
`

export const Total = styled.span`
  display: inline-block;
  margin-bottom: ${space.sm};
  padding: ${space.xs} ${space.xl2};
  border-radius: ${borders.radius.lg};
  background-color: ${color.grayscale.light};
  color: ${color.primary.pure};
  font-family: ${font.family.heading};
  font-size: ${font.size.h3};
  font-weight: ${font.weight.bold};
  text-align: center;
  white-space: nowrap;
`

export const Table = styled.section`
  margin-bottom: ${space.lg};
  color: ${color.text.normal};
  font-size: ${font.size.small};
  text-align: right;
  white-space: nowrap;
`
export const TableContents = styled.section`
  position: relative;
  img {
    position: absolute;
    top: 0.1rem;
    right: -0.5rem;
  }
`

export const TableHead = styled.div`
  text-align: center;
  background-color: ${color.grayscale.light};
  padding-top: ${space.xs2};
  padding-bottom: ${space.xs2};
  font-weight: ${font.weight.medium};
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding: ${space.xs2} ${space.xl5} ${space.xs2} 0;
  @media (min-width: ${breakpoint.xl4}) {
    min-width: 23.4rem;
    padding: ${space.xs2} ${space.xl5} ${space.xs2} ${space.xl};
  }
`

export const TableRow = styled.div<{ highlight: boolean }>`
  text-align: center;
  margin-left: 0.5rem;
  border-width: ${borders.sizes.md};
  border-style: solid;
  border-color: ${(p) => (p.highlight ? color.primary.darker : 'transparent')};
  border-radius: ${borders.radius.lg};
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  max-width: 14rem;
  @media (min-width: ${breakpoint.xl3}) {
    max-width: 17.3rem;
  }
  @media (min-width: ${breakpoint.xl4}) {
    max-width: 16rem;
    margin-left: 3rem;
  }
`

export const TableCell = styled.div`
  padding: ${space.xs2} ${space.xs};
`

export const Checkmark = styled.i`
  display: block;
  position: absolute;
  left: 6px;
  top: calc(50% - 2px);
  width: 6px;
  height: 9px;
  border-style: solid;
  border-color: ${color.primary.pure};
  border-width: 0 ${borders.sizes.md} ${borders.sizes.md} 0;
  transform: rotate(45deg);
`

export const Delivery = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${space.xl};
  gap: ${space.md};
`

export const DeliveryType = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${space.xs};
  border-radius: ${borders.radius.lg};
  border: ${borders.sizes.md} solid ${({ selected }) => (selected ? color.primary.darker : 'transparent')};
  transition: border 0.3s ease;
  cursor: pointer;

  .icon {
    margin-right: ${space.xs};
    color: ${color.grayscale.dark};

    @media (min-width: ${breakpoint.hd}) {
      margin-right: ${space.sm};
    }
  }

  p {
    line-height: 1.2;
  }
`

export const QuotationMailSender = styled.div`
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  padding: ${space.xs};
`

export const QuotationContainer = styled.div`
  .background {
    position: absolute;
  }

  .modal {
    opacity: 1;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  .modal-hidden {
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
`

export const AddressForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${space.lg};
  padding: ${space.sm};
`
export const DividerLine = styled.div`
  width: 18rem;
  border-top: none;
  border: 1px dashed ${color.grayscale.gray};
  margin-bottom: ${space.sm};
`
