import { FC } from 'react'
import { Icon, Text } from '@/client/components'
import * as S from './styles'
import { ResultFooterProps } from '../types'

export const ResultFooter: FC<ResultFooterProps> = ({ texts }) => {
  return (
    <S.ResultFooter>
      <S.ExtraInfos>
        <S.ExtraInfosItem href={texts?.extra?.file_link} target='_blank'>
          <Icon name='data-sheet' size='s4' />
          <Text size='xs' color='weak' margin='0' lineHeight='1.3'>
            {texts?.extra?.file_label}
          </Text>
        </S.ExtraInfosItem>

        <S.Paragraph
          dangerouslySetInnerHTML={{ __html: texts.more_info.info_sentence }}
          style={{ fontSize: '0.75rem', padding: '0', margin: '0' }}
        />
      </S.ExtraInfos>

      <S.DividerLine />

      <Text size='xs' color='weak' margin='0' padding='0' italic>
        {texts.footer_message}
      </Text>
      <S.DividerLine />

      <S.ExtraInfos>
        <S.ExtraInfosItemSmall href={texts?.help?.file_link} target='_blank'>
          <Icon name='info' size='s4' />
          <S.Paragraph
            dangerouslySetInnerHTML={{ __html: texts?.help?.file_label }}
            style={{ fontSize: '0.75rem', padding: '0', margin: '0' }}
          />
        </S.ExtraInfosItemSmall>
      </S.ExtraInfos>
    </S.ResultFooter>
  )
}
