import styled, { css } from 'styled-components'
import { CheckboxStyleProps } from './types'
import borders from '../theme/borders'
import color from '../theme/color'
import space from '../theme/space'

const cssDisabled = css`
  cursor: disabled;
  pointer-events: none;
  opacity: 0.4;
`

export const CheckboxContainer = styled.label<{ checked?: boolean; openDropdown?: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  min-height: 2.5rem !important;
  height: 2.5rem;
  padding: 1rem 0;
  border: ${(props) => (props.openDropdown ? `${borders.sizes.sm} solid ${color.grayscale.darkSilver}` : 'none')};
  border-bottom: ${(props) =>
    props.openDropdown ? 'none' : `${borders.sizes.sm} solid ${color.grayscale.darkSilver}`};
  background-color: ${(props) => (props.openDropdown ? color.grayscale.silver : 'none')};
  padding-right: ${(props) => (props.openDropdown ? '0.25rem' : '0')};
  ${(props) => props.disabled && cssDisabled};
`

export const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`
/**** to be updated ***/
export const Shape = styled.span<CheckboxStyleProps>`
  position: relative;
  display: inline-block;
  height: 1.8rem;
  width: 1.8rem;
  margin-right: ${space.sm};
  border: ${borders.sizes.md};
  border-radius: 50%;
  user-select: none;
  cursor: pointer;
  transition: all 0.4s ease;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 1.2rem;
    width: 1.2rem;
    background-color: white;
    border-radius: 50%;
    border: ${borders.sizes.md} solid ${color.grayscale.black};
    transition: all 0.4s ease;
  }
`

export const InnerShape = styled.i<CheckboxStyleProps>`
  display: block;
  position: absolute;
  ${(p) =>
    p.kind === 'circle' &&
    p.checked &&
    css`
      top: 50%;
      left: 50%;
      margin: auto;
      border-radius: 50%;
      transition: all 0.1s ease;
      transform: translate(-50%, -50%);
      width: 0.7rem !important;
      height: 0.7rem !important;
      border: ${borders.sizes.md} solid ${color.grayscale.black};
      background-color: ${color.primary.black};
    `}
`

export const Infos = styled.span<{ displayType?: string }>`
  display: ${({ displayType }) => (displayType && displayType !== '' ? displayType : 'flex')};
  flex-wrap: wrap;
  margin-top: ${borders.sizes.sm};
  color: ${({ displayType }) => (displayType && displayType !== '' ? color.grayscale.darker : color.grayscale.black)};
`

export const RightEnd = styled.div`
  margin-left: auto;
`

export const Icon = styled.div`
  margin-left: auto;
`
